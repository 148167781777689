import { required } from 'vuelidate/lib/validators'

const uniquePhoneType = (value, phones) => {
  const types = phones.map(p => p.type)
  return new Set(types).size == types.length
}

export default {
  phones: {
    $each: {
      no: {
        required
      }
    },
    types: {
      uniquePhoneType
    }
  }
}
