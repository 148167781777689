import { required } from 'vuelidate/lib/validators'

const uniqueAddressType = (value, addresses) => {
  const types = addresses.map(a => a.type)
  return new Set(types).size == types.length
}

export default {
  addresses: {
    $each: {
      address1: {
        required
      },
      suburbText: {
        required
      },
      stateCode: {
        required
      },
      stateText: {
        required
      },
      suburbPostcode: {
        required
      },
      countryCode: {
        required
      },
      countryText: {
        required
      }
    },
    types: {
      uniqueAddressType
    }
  }
}
